import { useTranslation } from "react-i18next";

import Avatar from "../Avatar"
import { formatTimeToAmPm, renderFormatteContent } from "../../Utils"
import ModalImageView from "./ModalImageView"
import { BsThreeDotsVertical } from "react-icons/bs";
import DropDownMenu from "./dropdownChat";
import ReplyPreview from "./replyPreview";



const MessageUser = ({ msg, ismobile, receiver, onReply }) => {
    const { t } = useTranslation();
    const dataReply = {
        content: msg.content,
        pseudo: receiver.pseudo,
        replyId: msg._id
    }
    const menuOptions = [
        { label: t('views.messages.replyTo'), onClick: () => onReply(dataReply) },
    ];


    return (
        <div className="d-flex mb-2 chatparentReceiv " onContextMenu={(e) => e.preventDefault()}>
            <a href={receiver.account_type === 'CREATOR' && `/${receiver.pseudo}`} className={`text-decoration-none ${ismobile && 'small'}`}>
                <Avatar user={receiver} width={ismobile ? 30 : 40} height={ismobile ? 30 : 40} fontSize={`${ismobile ? 'small' : 'fs-6'}`} />
            </a>
            <div className="ms-0 small">
                <div className="d-flex position-relative">
                    <div className=" px-3 py-1  mb-1 chat-text " style={{ backgroundColor: '#D0D5DA', borderRadius: "0px 15px 15px 15px" }} >
                        {msg.file && msg.file.map((file, index) => (
                            <div key={index} className={`${index === file.length - 1 ? '' : 'mb-2'}`}>
                                <>
                                    <ModalImageView msgId={file._id} fileLink={file.link} pseudo={receiver.pseudo} />
                                    {file.mime_type === 'image' ? (
                                        <div data-bs-toggle="modal" data-bs-target={`#p_imageview${file._id}`} className={` bg-transparent position-relative`}>
                                            <img className='img-fluid img' src={file.link} alt="file-img" style={{ maxHeight: 400, maxWidth: 280 }} onContextMenu={(e) => e.preventDefault()} />
                                        </div>
                                    ) : (
                                        <div className="position-relative">
                                            <video preload="auto" src={file.link} controls controlsList="nodownload" className="img-fluid" style={{ maxHeight: 400, maxWidth: 280 }} onContextMenu={(e) => e.preventDefault()}></video>
                                        </div>
                                    )}

                                </>
                            </div>
                        ))
                        }
                        {msg.reply_content &&
                            <ReplyPreview replyContent={msg.reply_content} />
                        }
                        <>
                            {msg.content.length > 0 &&
                                <span
                                    className={` text-muted fs-6 fw-200 d-inline-block ${msg.content?.length > 20 ? 'costom-control ' : 'normal'}`} >
                                    {renderFormatteContent(msg.content)}
                                </span>
                            }
                        </>
                    </div>

                    <DropDownMenu
                        className="mb-5 ms-4"
                        classParent="start-100 translate-middle"
                        buttonClassName=" dropend dropchatReceiv"
                        menuOptions={menuOptions}

                    />

                </div>

                <div className="align-items-center ms-2">
                    <span className="small text-muted">{formatTimeToAmPm(msg.created_at, t) !== "Invalid Date" && formatTimeToAmPm(msg.created_at, t)}</span>
                </div>

            </div>
        </div >

    )
}
export default MessageUser