import React, { useState } from 'react';
import { RiSearchLine } from 'react-icons/ri';

const ChatHeader = ({ t }) => {
    const [showSearchInput, setShowSearchInput] = useState(false);

    return (
        <div
            className="border-bottom bg-white fix-sidebar top-0 shadow-sm"
            style={{ padding: '19px', height: '60px' }}
        >
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex">
                    <div className="rounded-circle">
                        {!showSearchInput ? (
                            <a
                                href="/messages"
                                className="material-icons text-dark text-decoration-none border-0 bg-transparent pt-1"
                            >
                                arrow_back
                            </a>
                        ) : (
                            <span
                                style={{ cursor: 'pointer' }}
                                className="bg-transparent text-decoration-none material-icons border-0 pt-1"
                                onClick={() => setShowSearchInput(!showSearchInput)}
                            >
                                close
                            </span>
                        )}
                    </div>

                    {!showSearchInput ? (
                        <div className="align-items-center w-100 mb-0 fw-bold text-body fs-6 text-black d-flex ms-2">
                            {t('views.messages.contact')}
                        </div>
                    ) : (
                        <form className="bg-white input-group overflow-hidden rounded-pill d-flex align-items-center">
                            <input
                                type="text"
                                name="search"
                                placeholder={t('navigation.findCreator')}
                                className="form-control border-0 fw-light ps-1"
                            />
                        </form>
                    )}
                </div>

                <button
                    className={`bg-transparent text-black material-icons border-0 justify-content-end text-decoration-none pb-1 ${showSearchInput && 'text-muted'}`}
                    disabled={showSearchInput}
                    onClick={() => setShowSearchInput(!showSearchInput)}
                >
                    <RiSearchLine size={20} />
                </button>
            </div>
        </div>
    );
};

export default ChatHeader;
