import React from 'react';

const ReplyAlert = ({ replyingTo, onClose }) => {
    if (!replyingTo) return null;

    return (
        <div
            className="alert alert-white alert-dismissible fade show border-bottom"
            role="alert"
            style={{ borderLeft: '5px solid #37b8f8' }}
        >
            <div className="d-flex justify-content-between align-items-center">
                <span className="me-auto small text-muted">{replyingTo.pseudo}</span>
                <button
                    onClick={onClose}
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                ></button>
            </div>
            <strong className="d-block mt-2">"{replyingTo.content}</strong>
        </div>
    );
};

export default ReplyAlert;
