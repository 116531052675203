import React from 'react';
import Avatar from '../Avatar';
import { Images } from '../../Constants';
import { shortenName } from '../../Utils';

const UserChatItem = ({ user, onlineStatus, createChatWithCreator }) => {

    return (
        <div
            key={user._id}
            className='bouton-survol'
            onClick={() => createChatWithCreator(user._id)}
            style={{ cursor: 'pointer' }}
        >
            <div className={`p-2 d-flex text-dark text-decoration-none account-item bg-transparent custom-border-style bouton-survol`}>
                <div className="position-relative border-bottom-0">
                    <Avatar user={user} width={50} height={50} fontSize="fs-6" />
                    {onlineStatus && (
                        <span
                            className="position-absolute translate-middle p-1 border border-green rounded-circle"
                            style={{ top: '39px', left: '84%', backgroundColor: '#35D063' }}
                        >
                            <span className="visually-hidden">New alerts</span>
                        </span>
                    )}
                </div>
                <div className='ms-3'>
                    <p className="fw-bold mb-0 pe-1 d-flex align-items-center">
                        <span>{shortenName(user.display_name, 10)}</span>
                        {user.account_type === 'CREATOR' && (
                            <span className="ms-0 material-icons p-0 fw-bold text-secondary rounded-circle ov-icon md-0 mb-1">
                                {Images.verif}
                            </span>
                        )}
                    </p>
                    <div className="text-muted fw-light d-flex justify-content-between">
                        <span className="text-muted d-flex align-items-center small">
                            @ {shortenName(user.pseudo, 10)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserChatItem;
