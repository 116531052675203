//React
import React from "react";

//Utils
import { renderFormatteContent } from "../../Utils";

const ReplyPreview = ({ replyContent }) => {
    return (
        <>
            <div>
                {replyContent && replyContent.length > 0 && (
                    <span
                        className={`text-black fs-6 fw-200 d-inline-block mt-0 fst-italic ${replyContent.length > 20 ? "costom-control" : "normal"}`}
                    >
                        {renderFormatteContent(replyContent)}
                    </span>
                )}
            </div>
            <hr />
        </>
    );
};

export default ReplyPreview;
