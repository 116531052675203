import React from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';

const DropDownMenu = ({
    menuOptions,
    className = '',
    classParent = '',
    buttonClassName = 'dropchatSend',
}) => {
    return (
        <div className={`position-absolute top-100 ${classParent}`} style={{ zIndex: 1 }}>
            <div className={`d-flex btn-group ${className}`}>
                <button
                    type="button"
                    className={`btn btn-transparent btn-sm dropdown-toggle ${buttonClassName}`}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    id='dropstart'

                >
                    <BsThreeDotsVertical />
                </button>
                <ul className="dropdown-menu bg-white">
                    {menuOptions.map((option, index) => (
                        <li key={index}>
                            <button className="dropdown-item" onClick={option.onClick}>
                                {option.label}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default DropDownMenu;
