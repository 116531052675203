import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Images } from "../../Constants";
import { createSocket, TchatRequest, RequestFanbusy } from "../../Services";
import TchatForm from "./TchatForm";
import { Loader, MessageBlocked, MessageSendBySender, MessageUser } from "../../Components";
import { formatDateRelative, shortenName } from "../../Utils";
import { getRoomAndAllStatusReceiverByUser } from "../../Utils";

const TchatMessage = ({ status, dataSocket }) => {
    const { t } = useTranslation();
    const { room, receiverId } = useParams();
    const users = useSelector(state => state.users);
    const client_id = users.users._id;
    const messageListRef = useRef();
    const [isMobile, setIsMobile] = useState(false);
    const [can_not_writeUsers, setCan_not_writeUsers] = useState(false);
    const [subscription, setSubscription] = useState(true);
    const [refreshMessages, setRefreshMessages] = useState(0);
    const [socket, setSocket] = useState(null);
    const [receiveruser, setReceiveruser] = useState({});
    const [replyingTo, setReplyingTo] = useState({});

    const [state, setState] = useState({
        isLoading: true,
        isLoadingMorMessage: false,
        scrollTop: 1,
        tchatMessage: [],
        statusUsers: {},
        youCanScroll: false
    });

    const [pagination, setpagination] = useState({
        total: 0,
        per_page: 25,
        last_page: 0,
        current_page: 0,
    })
    const nextPage = pagination.current_page + 1;

    useEffect(() => {
        const newSocket = createSocket(room, client_id);
        setSocket(newSocket);
        return () => {
            if (newSocket && newSocket.readyState === WebSocket.OPEN) {
                newSocket.close();
            }
        };
    }, [client_id, room]);

    useEffect(() => {
        if (socket) {
            socket.onmessage = (event) => {
                const dataSocket = JSON.parse(event.data);
                if (dataSocket.type === 'message') {
                    setState((prevState) => {
                        const nextState = [...prevState.tchatMessage, dataSocket];
                        return { ...prevState, tchatMessage: nextState, youCanScroll: true };
                    });
                }
                else if (dataSocket.type === 'lock') {
                    setCan_not_writeUsers(dataSocket.can_not_write)
                }
                else if (dataSocket.type === 'souscription') {
                    setSubscription(dataSocket.ending);

                }
            };
        }
        return () => {
            if (socket) {
                socket.onmessage = null;
            }
        };
    }, [socket]);

    useEffect(() => {
        if (dataSocket && dataSocket.type === 'statut') {
            setState((prevState) => ({
                ...prevState,
                statusUsers: {
                    ...prevState.statusUsers, [dataSocket.user_id]: dataSocket.online ? 'En ligne' : 'Hors ligne',
                },
            }));
        }
    }, [dataSocket]);

    useEffect(() => {
        const getReceiverById = async () => {
            const creatorResponse = await RequestFanbusy(`creators/${receiverId}`, 'GET', '', users.access_token);
            if (creatorResponse.status === 200) {
                const receiver = creatorResponse.response.data
                setReceiveruser(receiver)
                getAutorisationByUsers(receiver?.tchat_fee)
            }
        }
        getReceiverById();

        const getAutorisationByUsers = async (tchat_fee) => {
            const response = await TchatRequest(`room/get/write/by/${room}/${client_id}/${tchat_fee}`, 'GET', '', '');
            if (response.response.status_code === 200) {
                setSubscription(response.response.data.souscription);
                setCan_not_writeUsers(response.response.data.can_not_write);
            }

        };


        const getMessagesByRoom = async () => {
            const response = await TchatRequest('message/' + room, 'GET', '', '');
            if (response.status === 200) {
                let messages = response.response.data;
                const reversedMessages = messages.reverse();
                setState(prevState => ({ ...prevState, tchatMessage: reversedMessages, isLoading: false, youCanScroll: true }));
            }
        }
        getMessagesByRoom();

        let stat = getRoomAndAllStatusReceiverByUser(users.users._id);
        stat.then(async (value) => {
            const statusUsersObject = {};
            if (value.length > 0) {
                value.forEach((status) => {
                    statusUsersObject[status.statut.user_id] = status.statut.online ? 'En ligne' : 'Hors ligne';
                });
            }
            setState(prevState => ({ ...prevState, statusUsers: statusUsersObject || status }));
        })

        const firstloadmessage = async () => {
            const response = await TchatRequest(`message/${room}`, 'GET', '', '');
            const pagination = response.response.paginage;
            if (response.status === 200) {
                setpagination(prevState => ({ ...prevState, total: pagination.total, last_page: pagination.last_page, current_page: pagination.current_page }));
            }
        }
        firstloadmessage();

    }, [client_id, receiverId, receiveruser?.tchat_fee, room, status, users.access_token, users.users._id]);

    const handleRefreshMessages = () => {
        setRefreshMessages(refreshMessages + 1)
    }

    useEffect(() => {
        checkIsMobile();
        window.addEventListener("resize", checkIsMobile);
        return () => window.removeEventListener("resize", checkIsMobile);
    }, []);

    useEffect(() => {
        if (messageListRef.current && state.youCanScroll === true) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    }, [state.tchatMessage, room, users.access_token, receiveruser?._id, state.youCanScroll]);

    const loadMoreMessages = useCallback(async () => {
        setState((prevState) => ({ ...prevState, isLoadingMorMessage: true, youCanScroll: false }));
        const response = await TchatRequest(`message/${room}?skip=${nextPage}&limit=${pagination.per_page}`, 'GET', '', '');
        if (response.status === 200) {
            const paginage = response.response.paginage; let messages = response.response.data;
            const reversedMessages = messages.reverse();
            setpagination(prevState => ({ ...prevState, total: paginage.total, last_page: paginage.last_page, current_page: paginage.current_page }));
            setState((prevState) => ({ ...prevState, tchatMessage: [...reversedMessages, ...prevState.tchatMessage], isLoadingMorMessage: false, youCanScroll: false }));

        }
    }, [nextPage, pagination.per_page, room]);



    function checkIsMobile() {
        setIsMobile(window.innerWidth <= 768);
    }

    const checkDate = (date, index) => {
        try {
            const currentDate = date ? new Date(date.split("T")[0]) : null;

            if (index > 0) {
                const previousDate = state.tchatMessage[index - 1]?.created_at
                    ? new Date(state.tchatMessage[index - 1].created_at.split("T")[0]) : null;

                if (currentDate && previousDate) {
                    return currentDate.toISOString().split("T")[0] !== previousDate.toISOString().split("T")[0];
                }
            }
            return true;
        } catch (error) {
            return true;
        }
    };



    const handleRefrechMessage = (messageId) => {
        setState((prevState) => {
            const updatedMessages = prevState.tchatMessage.map((msg) =>
                msg._id === messageId ? { ...msg, is_lock: false } : msg
            );
            return { ...prevState, tchatMessage: updatedMessages, youCanScroll: false };
        });
    };

    return (
        <div className="my-5">
            <div className={`${!isMobile ? 'position-absolute  ' : 'fixed-top '} d-flex align-items-center top-0  bg-white shadow-sm w-100 p-3`} style={{ height: '60px' }} >
                <a href='/messages' className="material-icons text-dark  border-0 bg-white text-decoration-none">arrow_back</a>
                <div className="ms-3">
                    <div className="d-flex mb-0 pe-1 align-items-center fs-6 text-black " >
                        <span className="fw-bold">{shortenName(receiveruser?.display_name, 15)}</span>
                        {receiveruser?.account_type === 'CREATOR' &&
                            <span className="ms-0 material-icons  fw-bold text-secondary  rounded-circle ov-icon  ">{Images.verif}</span>
                        }
                    </div>
                    {state.statusUsers[receiveruser?._id] === 'En ligne' ?
                        <div className="d-flex ms-1">
                            <span className="position-absolute translate-middle p-1 border border-white rounded-circle " style={{ marginTop: '9px', backgroundColor: '#35D063' }}>
                                <span className="visually-hidden ">New alerts</span>
                            </span>
                            <span className="ms-1 material-icons  fw-bold text-secondary  rounded-circle ov-icon  ">    <span className="visually-hidden ">New alerts</span></span>
                            <span className="small text-muted"> {t('views.messages.online')}</span>
                        </div>
                        :
                        <span> {t('views.messages.offline')}</span>
                    }
                </div>
            </div>
            <div className={` align-items-center justify-content-center my-5 py-4 messages-chat `} id='messageList' >
                {state.isLoading ?
                    <div className="text-center position-fixed top-50">
                        <Loader styleColor={true} />
                    </div>
                    :
                    (state.tchatMessage <= 0) ?
                        <div class="position-fixed top-50 w-100">
                            <div class="text-center">
                                <h2 class="fs-6 text-black ">{t('views.messages.youHaveNotMessages')}</h2>
                            </div>
                        </div>
                        :
                        <div ref={messageListRef} className={`container-fluid height-room px-3 pb-5`} style={{ overflowY: 'auto', paddingTop: "70px" }} id="container-msg">
                            {(pagination.current_page !== pagination.last_page) && state.tchatMessage.length >= 25 &&
                                <div className="text-center">
                                    <button style={{ borderRadius: 20 }} className="btn fb-btn-color border-0 text-white" onClick={() => { loadMoreMessages() }}>{t('views.messages.loadMoreMessages')}</button>
                                </div>
                            }
                            {(state.isLoadingMorMessage) && (
                                <div className="text-center  text-muted my-3 fw-bold fs-6">
                                    <div className="spinner-border spinner-border-sm text-secondary" role="status">
                                        <span className="visually-hidden small">Loading...</span>
                                    </div>
                                </div>

                            )}
                            {state.tchatMessage.map((msg, index) => {
                                const showDateSeparator = checkDate(msg?.created_at, index)
                                return (
                                    <div >
                                        {showDateSeparator && formatDateRelative(msg.created_at, t) !== "Invalid Date" && (
                                            <div key={index} className="date-separator d-flex justify-content-between ">
                                                <hr />
                                                <span>{formatDateRelative(msg.created_at, t)}</span>
                                                <hr />
                                            </div>
                                        )}
                                        {msg.sender_id !== users.users._id ?
                                            (
                                                !msg.is_lock ? (
                                                    <MessageUser msg={msg} key={msg._id} ismobile={isMobile} receiver={receiveruser} onReply={setReplyingTo} />
                                                ) : (
                                                    (msg.file != null && msg.unlock_fee !== 0) && (
                                                        <MessageBlocked
                                                            msg={msg}
                                                            ismobile={isMobile}
                                                            key={msg._id}
                                                            receiver={receiveruser}
                                                            refrech={handleRefrechMessage}
                                                            onReply={setReplyingTo}
                                                        />
                                                    )
                                                )
                                            )
                                            :
                                            <MessageSendBySender msg={msg} key={msg._id} onReply={setReplyingTo} />
                                        }
                                    </div>
                                );
                            })}
                        </div>

                }
            </div>
            <div >
                <TchatForm
                    onRefreshMessages={handleRefreshMessages}
                    socket={socket}
                    tchatMessage={state.tchatMessage}
                    content={state.content}
                    receiver={receiveruser}
                    can_not_writeUsers={can_not_writeUsers}
                    setCan_not_writeUsers={setCan_not_writeUsers}
                    room={room}
                    subscription={subscription}
                    replyingTo={replyingTo}
                    setReplyingTo={setReplyingTo}
                />
            </div>
        </div>
    )
}
export default TchatMessage