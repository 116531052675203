import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import 'react-phone-number-input/style.css'
//import PhoneInput from 'react-phone-number-input'

import Loader from "./Loader";
import { RequestFanbusy, TchatRequest } from "../Services";
import { convertCurrency, getCreatorFollows, getPaymentCard, getPaymentModeByCountry, getReleasePost, getStatutByUser, shortenName } from "../Utils";
import { Images } from "../Constants";
import Avatar from "./Avatar";
//import AddCardPayment from "./AddCardPayment";



const TransactionsModal = ({ action, nameForm, amount, data, onDataReady, setListPostRelease, setFollows, refrech, setCan_not_writeUsers, setListCode, period }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const darkMode = useSelector((state) => state.theme.darkMode);
    const users = useSelector((state) => state.users);
    const params = useSelector((state) => state.params);
    const transactionMoRef = React.useRef(null);
    const [isModalOpen, setIsModalOpen] = React.useState(true);
    // const [showPhone, setShowPhone] = React.useState(false);
    const [showNewCard, setShowNewCard] = React.useState(false);
    const [showReleaseError, setShowReleaseError] = React.useState(true);
    const [credited, setCredited] = React.useState(false);
    const [refreshKey, setRefreshKey] = React.useState(0);
    const [amountt, setAmountt] = React.useState({
        maxAmount: 0,
        minAmount: 0,
    });
    const [isOnLine, setIsOnLine] = React.useState(false);
    const [releaseErrors, setReleaseErrors] = React.useState('');
    const [alertTpe, setAlertType] = React.useState('');
    const [state, setState] = React.useState({
        pay4meCode: "",
        amount: parseFloat(amount),
        minAmount: 0,
        balance: 0,
        amountConvert: '',
        tipMessage: '',
        mode: '',
        cardNumber: '',
        cardId: '',
        otp: '',
        tx_ref: '',
        phoneCode: '',
        transaction_ref: '',
        transaction_id: '',
        request_transaction_id: false,
        phoneNumber: users.users?.phone_number,
        paymentMode: [],
        cardPayment: [],
        isValidated: true,
        credited: false,
        convert: false,
        isLoading: false
    });

    const convertAmount = React.useCallback((amountConvert, type) => {
        let convert = convertCurrency(params.currency, params.currencies, amountConvert, type)?.replace(/\s/g, '');
        return parseFloat(convert);
    }, [params.currency, params.currencies])

    let balanceCreator = parseFloat(convertAmount(users.account?.balance, 'GET'));

    const checkBalanceCreator = () => {
        if (balanceCreator >= state.amount) {
            return true
        }
        return false
    }

    const textInputChange = (input) => {
        const target = input.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'tipAmount') {
            setState({ ...state, amount: value });
            checkBalanceCreator();
        }
        else if (name === 'tipMessage') {
            setState({ ...state, tipMessage: value });
        }
        else if (name === 'modePaymentTip') {
            setState({ ...state, mode: value });
        }
        else if (name === 'cardId') {
            setState({ ...state, cardId: value });
        }
        else if (name === 'cardNumber') {
            setState({ ...state, cardNumber: value });
        }
        else if (name === 'otp') {
            setState({ ...state, otp: value });
        }
        else if (name === 'transaction_id') {
            setState({ ...state, transaction_id: value });
        }
        else if (name === 'pay4meCode') {
            setState({ ...state, pay4meCode: value });
        }
    }

    const subscribeToCreator = async () => {
        setState((prev) => ({ ...prev, isLoading: true }));
        if (state.mode === 'pay4me' || state.mode === 'MOBILE_MONEY_XAF') {
            const form = {
                creator_id: data.id,
                pay4me_payment: state.mode === 'pay4me' ? true : false,
                pay4me_code: state.pay4meCode
            };
            let res = await RequestFanbusy('souscriptions/', 'POST', form, users.access_token);

            if (res.status === 201) {
                setCredited(false)
                setAlertType('success')
                setReleaseErrors(t('navigation.subscribingThisCreatorWasSuccess'))
                onDataReady(t('navigation.subscribingThisCreatorWasSuccess'))
                if (transactionMoRef.current) {
                    transactionMoRef.current.click();
                }
                setState((prev) => ({ ...prev, isLoading: false, pay4meCode: "" }));
                setIsModalOpen(false);
                dispatch({ type: "REFRESH", value: 1 });
                let follows = getCreatorFollows(users.access_token);
                follows.then((value) => {
                    setFollows(value)
                });


            } else if (res.response.status_code === 402 && res.response.description === "pay4me already use") {
                setShowReleaseError(true)
                setAlertType('warning')
                setReleaseErrors(t('views.account.pay4meAlreadyUse'))
                setState({ ...state, isLoading: false });
            }
            else if (res.response.status_code === 406 && res.response.description === "pay4me amount dont match") {
                setShowReleaseError(true)
                setAlertType('warning')
                setReleaseErrors(t('views.account.pay4meAmount'))
                setState({ ...state, isLoading: false });
            } else if (res.response.status_code === 404) {
                setShowReleaseError(true)
                setAlertType('warning')
                setReleaseErrors(t('views.account.pay4meCodeNotFund'))
                setState({ ...state, isLoading: false });
            }
            else if (res.status === 500) {
                setShowReleaseError(true)
                setReleaseErrors(t('navigation.tryAgain'))
                setState({ ...state, isLoading: false });
            }
            else {
                setAlertType('warning')
                setReleaseErrors(t('navigation.tryAgain'))
                setState({ ...state, isLoading: false });
            }
        } else {
            setCredited(false)
            onDataReady(t('navigation.subscribingThisCreatorWasSuccess'))
            if (transactionMoRef.current) {
                transactionMoRef.current.click();
            }
            setState((prev) => ({ ...prev, isLoading: false }));
            setIsModalOpen(false);
            dispatch({ type: "REFRESH", value: 1 });
            let follows = getCreatorFollows(users.access_token);
            follows.then((value) => {
                setFollows(value)
            });
        }
    };

    const releasePost = async (post_id) => {
        if (state.mode === "MOBILE_MONEY_XAF") {
            let form = {
                payment_mode: state.mode
            }
            setState((prev) => ({ ...prev, isLoading: true }));
            let res = await RequestFanbusy(`posts/${post_id}/release-post`, 'POST', form, users.access_token);
            if (res.status === 201) {
                setCredited(false)
                onDataReady(t('navigation.postUnlockWithSusses'))
                if (transactionMoRef.current) {
                    transactionMoRef.current.click();
                }
                setState((prev) => ({ ...prev, isLoading: false }));
                setIsModalOpen(false);
                dispatch({ type: "REFRESH", value: 1 });
                let releasePost = getReleasePost(users.access_token);
                releasePost.then((value) => {
                    setListPostRelease(value);
                });
            }
            else if (res.status === 422 && res.response.response_type === "Unprocessable Entity") {
                setShowReleaseError(true)
                setReleaseErrors(t('navigation.anErrorOcured'))
                setState({ ...state, mode: '', isLoading: false });
            }
            else if (res.status === 500) {
                setShowReleaseError(true)
                setReleaseErrors(t('navigation.tryAgain'))
                setState({ ...state, mode: '', isLoading: false });
            }
        } else {
            setCredited(false)
            onDataReady(t('navigation.postUnlockWithSusses'))
            if (transactionMoRef.current) {
                transactionMoRef.current.click();
            }
            setState((prev) => ({ ...prev, isLoading: false }));
            setIsModalOpen(false);
            dispatch({ type: "REFRESH", value: 1 });
            let releasePost = getReleasePost(users.access_token);
            releasePost.then((value) => {
                setListPostRelease(value);
            });

        }
    }

    const handleSenTipCreator = async () => {

        if (state.amount === 0 || isNaN(state.amount)) {
            setState({ ...state, releaseErrors: `${t('navigation.pleaseEnterAValidNumber')}` });
        }
        else {

            if (state.mode === 'MOBILE_MONEY_XAF') {
                setState((prev) => ({ ...prev, isLoading: true }));

                const form = {
                    ...(data.post ? { post_id: data.postId } : { creator_id: data.id }),
                    amount: convertCurrency(params.currency, params.currencies, state?.amount, 'POST'),
                    payment_mode: checkBalanceCreator() ? 'ACCOUNT' : state.mode
                };
                let res = null
                if (data.post) {
                    res = await RequestFanbusy(`posts/${data.postId}/leave-tip`, 'POST', form, users.access_token);
                }
                else {
                    res = await RequestFanbusy('creators/fans/leave-tip', 'POST', form, users.access_token);
                }

                if (res.status === 201) {
                    setCredited(false)
                    onDataReady(t('navigation.tipSendWithSuccess'))
                    if (transactionMoRef.current) {
                        transactionMoRef.current.click();
                    }
                    setState((prev) => ({ ...prev, amount: 0, tipMessage: '', isLoading: false }));
                    setIsModalOpen(false);
                }
                else if (res.status === 422 && res.response.response_type === "Unprocessable Entity") {
                    setShowReleaseError(true)
                    setReleaseErrors(t('navigation.anErrorOcured'))
                    setState({ ...state, amount: 0, mode: '', isLoading: false });
                    setRefreshKey(prevKey => prevKey + 1);
                }
                else if (res.status === 400 && res.response.description === "Insuffisant fund to provide this transaction") {
                    setShowReleaseError(true)
                    setReleaseErrors(t('navigation.insuffisantFundToProvideThisTransaction'))
                    setState({ ...state, amount: 0, mode: '', isLoading: false });
                    setRefreshKey(prevKey => prevKey + 1);
                }
                else {
                    setShowReleaseError(true)
                    setReleaseErrors(t('navigation.tryAgain'))
                    setState({ ...state, mode: '', isLoading: false });
                    setRefreshKey(prevKey => prevKey + 1);
                }
            } else {
                setCredited(false)
                onDataReady(t('navigation.tipSendWithSuccess'))
                if (transactionMoRef.current) {
                    transactionMoRef.current.click();
                }
                setState((prev) => ({ ...prev, amount: 0, tipMessage: '', isLoading: false }));
                setIsModalOpen(false);
                setRefreshKey(prevKey => prevKey + 1);
            }

        }


    }

    const unlockRoomByUsers = async () => {

        setState((prev) => ({ ...prev, isLoading: true }));

        const res = await TchatRequest(`room/${data.id}/${users.users._id}`, "PUT", "", users.access_token);
        if (res.response.status_code === 200) {
            setCredited(false)
            onDataReady(t('navigation.roomUnlockWithSuccess'))
            if (transactionMoRef.current) {
                transactionMoRef.current.click();
            }
            setState((prev) => ({ ...prev, isLoaded: false }));
            setIsModalOpen(false);
            dispatch({ type: "REFRESH", value: 1 });
            setCan_not_writeUsers(res.response.data.is_lock)
        } else if (res.response.status_code === 400 && res.response.response_type === "Error transaction") {
            setState((prev) => ({ ...prev, unlockError: `${t('navigation.insuffisantFundToProvideThisTransaction')}` }));
        }
        else if (res.response.status_code === 400 && res.response.response_type === "Bad Request") {
            setState((prev) => ({ ...prev, unlockError: `${t('navigation.youDontFollowThisCreator')}` }));
        }
        else {
            setState((prev) => ({ ...prev, unlockError: `${t('navigation.tryAgain')}` }));
        }
    }

    const unlockTchatMessage = async () => {
        setState((prev) => ({ ...prev, isLoading: true }));
        let res = await TchatRequest(`message/unlock/${data.id}`, 'PUT', '', users.access_token)
        if (res.response.status_code === 200) {
            onDataReady(t('navigation.messagesUnlockWithSuccess'))
            setCredited(false)
            if (transactionMoRef.current) {
                transactionMoRef.current.click();
            }
            setState((prev) => ({ ...prev, isLoaded: false }));
            setIsModalOpen(false);
            dispatch({ type: "REFRESH", value: 1 });
            refrech(data.id)
        }
        else if (res.response.status_code === 400 && res.response.response_type === "Error transaction") {
            setState((prevState) => ({ ...prevState, unlockReponse: (t('navigation.insuffisantFundToProvideThisTransaction')) }))
        }
        else if (res.response.status_code === 400 && res.response.response_type === "Bad Request") {

            setState((prevState) => ({ ...prevState, unlockReponse: `${t('navigation.youDontFollowThisCreator')}` }))

        }
        else {
            setState((prevState) => ({ ...prevState, unlockReponse: `${t('navigation.tryAgain')}` }))

        }
    };

    const addCode = async () => {
        if (state.mode === 'MOBILE_MONEY_XAF') {
            let form = {
                value: state.amount,
                currencies: params.currency
            }

            let res = await RequestFanbusy(`pay4me/`, 'POST', form, users.access_token);

            if (res.status === 201) {
                dispatch({ type: "REFRESH", value: 1 });
                setState((prev) => ({ ...prev, alertMessage: t('views.pay4me.codeCreated'), atertType: 'success' }));
                let res = await RequestFanbusy("pay4me/", 'GET', '', users.access_token)
                if (res.status === 200) {
                    setListCode(res.response.data)
                }
                if (transactionMoRef.current) {
                    transactionMoRef.current.click();
                }
            } else if (res.response.status_code === 406) {
                setState((prev) => ({ ...prev, releaseErrors: t('views.pay4me.tryAgain'), atertType: 'warning' }));
            } else if (res.response.status_code === 402) {
                setState((prev) => ({ ...prev, releaseErrors: t('views.pay4me.insufisantFund'), atertType: 'warning' }));
            } else {
                setState((prev) => ({ ...prev, releaseErrors: t('views.pay4me.badRequest'), atertType: 'warning' }));
            }
        } else {
            setState({ ...state, isLoading: true })
            dispatch({ type: "REFRESH", value: 1 });
            setState((prev) => ({ ...prev, alertMessage: t('views.pay4me.codeCreated'), atertType: 'success' }));
            let res = await RequestFanbusy("pay4me/", 'GET', '', users.access_token)
            setListCode(res.response.data)
            setState({ ...state, isLoading: false, amount: '' })
        }
    }

    const releaseCollection = async (collection_id) => {
        let form = {
            payment_mode: "ACCOUNT"
        }
        setState((prev) => ({ ...prev, isLoaded: true }));
        let res = await RequestFanbusy(`collection/${collection_id}/release-collection`, 'POST', form, users.access_token);
        if (res.status === 201) {
            setState({ ...state, isLoaded: false, releaseErrors: 'success' })
            setAlertType('success');
            dispatch({ type: "REFRESH", value: 1 });
            let releasePost = getReleasePost(users.access_token);
            releasePost.then((value) => {
                setListPostRelease(value);
            });
        }
        else if (res.status === 400) {
            setState({ ...state, haveFunds: false, isLoaded: false });
        } else {
            //  setShowReleaseError(true)
            setState({ ...state, releaseErrors: 'Error, please try again.', isLoaded: false });
            setAlertType('warning');
        }


    }


    const submitFormTransactionByAction = () => {
        if (action === 'leaveTip') {
            handleSenTipCreator()
        } else if (action === 'releasePost') {
            releasePost(data.postId)
        } else if (action === 'subscribeCreator') {
            subscribeToCreator()
        } else if (action === 'unlockTchat') {
            unlockRoomByUsers()
        } else if (action === 'unlockMessage') {
            unlockTchatMessage()
        } else if (action === 'pay4me') {
            addCode();
        } else if (action === 'PayCollection') {
            releaseCollection(data.postId)
        }
    }

    const handleCreditAccount = async (cardId) => {
        setState({ ...state, isLoading: true });
        if (isNaN(state.amount) || state.amount === 0 || state.amount < parseFloat(amountt.minAmount.replace(/\s/g, ''))) {
            setAlertType('warning')
            setReleaseErrors(`${t('navigation.pleaseEnterAValidAmountGreaterThanOrEqualTo')} ${amountt.minAmount}${params.currency}`)
            return
        }
        else {
            if (!credited) {
                setState((prev) => ({ ...prev, isLoading: true }));
                if ((users.users.phone_number === '' || (state.phoneNumber !== '')) && state.mode === 'MOBILE_MONE_XAF') {
                    const formUpdateCreator = {
                        full_name: users.users.full_name,
                        display_name: users.users.display_name,
                        pseudo: users.users.pseudo,
                        photo: users.users.photo,
                        banner: users.users.banner,
                        id_image: "",
                        id_card_image: "",
                        country_code: state.phoneCode,
                        phone_number: state.phoneNumber,
                        bio: users.users.bio,
                        localisation: users.users.localisation,
                        web_site: users.users.web_site,
                        account_type: users.users.account_type,
                        is_free_account: users.users.is_free_account,
                        subscription_fee: users.users.subscription_fee,
                        active_tchat: users.users.active_tchat,
                        tchat_fee: users.users.tchat_fee,
                        currency: users.users.currency,
                        lang: users.users.lang,
                        active_creator: users.users.active_creator,
                    }
                    let res = await RequestFanbusy("creators/", 'PUT', formUpdateCreator, users.access_token);

                    if (res.status === 422 && res.response.response_type === "Unprocessable Entity") {
                        setState({ ...state, phoneNumber: '', releaseErrors: `${t('navigation.anErrorOcured')}`, isLoading: false });
                    }
                }

                let referal_id = "";
                let referal_type = "";
                let referal_action = "";
                if (action === 'leaveTip') {
                    referal_id = data.post ? data.postId : data.id;
                    referal_type = data.post ? "POST" : "CREATOR";
                    referal_action = "LEAVE_TIP";
                } else if (action === 'releasePost') {
                    referal_id = data.postId;
                    referal_type = "POST";
                    referal_action = "RELEASE_POST";
                } else if (action === 'subscribeCreator') {
                    referal_id = data.id;
                    referal_type = "CREATOR";
                    referal_action = "SUBSCRIPTION";
                } else if (action === 'unlockTchat') {
                    referal_id = data.room;
                    referal_type = "TCHAT";
                    referal_action = "UNLOCK_TCHAT";
                } else if (action === 'unlockMessage') {
                    referal_id = data.id;
                    referal_type = "MESSAGE";
                    referal_action = "UNLOCK_MESSAGE";
                } else if (action === 'pay4me') {
                    referal_id = data.id;
                    referal_type = "PAY4ME";
                    referal_action = "CREATE_PAY4ME";
                }
                else if (action === 'PayCollection') {
                    referal_id = data.id;
                    referal_type = "POST";
                    referal_action = "RELEASE_COLLECTION";
                }


                let location = window.location;
                // const form = {
                //     amount: state.amount,
                //     payment_mode: state.mode,
                //     currency: params.currency,
                //     country: params.country,
                //     network: "*",
                //     card_id: cardId,
                //     redirect_url: location.origin + location.pathname,
                //     referal_id: referal_id,
                //     referal_type: referal_type,
                //     referal_action: referal_action
                // };
                const form = {
                    amount: state.amount,
                    payment_mode: state.mode,
                    currency: params.currency,
                    country: params.country,
                    network: "*",
                    card_id: "",
                    product_image: users.users.photo.length > 0 ? users.users.photo : "https://www.fanbusy.com/assets/img/logo.png",  // users.users.banner
                    product_label: "Credit Account",
                    redirect_url: location.origin + location.pathname,
                    redirect_view_url: location.origin + location.pathname,
                    referal_id: referal_id,
                    referal_type: referal_type,
                    referal_action: referal_action,
                    subscription_period: period
                }

                setState({ ...state, isLoading: true });

                if (state.mode === 'pay4me') {
                    setState({ ...state, isLoading: true });
                    submitFormTransactionByAction()
                    setState((prev) => ({ ...prev, isLoading: false }));
                    return
                }
                setAlertType('warning')
                setReleaseErrors(t('views.account.yourTransactionIsBeingProcessed'));
                // if (state.isValidated) {
                //     let res = await RequestFanbusy(`transactions/generic-transaction`, 'POST', form, users.access_token);
                //     console.log(res);
                //     if (res.response.status_code === 201) {
                //         setCredited(true)
                //         window.location.replace(res.response.data.link);
                //         // window.location.href = `${res.response.data.link}`
                //     }
                //     else if (res.status === 406) {
                //         setAlertType('warning')
                //         setState({ ...state, releaseErrors: t('navigation.yourLastTransactionIsBeingProcessed'), mode: '', alertType: 'warning', isLoaded: false });
                //         setRefreshKey(prev => prev + 1);
                //     }
                // }
                if (state.isValidated) {
                    let res = await RequestFanbusy(`accounts/credit/${users.account._id}?continent=${params.continent}`, 'POST', form, users.access_token);
                    if (res.response.status_code === 201) {
                        setCredited(true)
                        submitFormTransactionByAction()
                        setAlertType('success')
                        setReleaseErrors(t('views.account.transactionSuccessful'));
                        setState((prev) => ({ ...prev, isLoading: false }));
                    }
                    else if (res.response.status_code === 202) {
                        window.location.assign(res.response.data.payment_info.payment_form_url);

                        // if (state.mode === 'FMM') {
                        //     setShowReleaseError(true);
                        //     let message;
                        //     if (res.response.data.narration?.includes('Orangemoney CM')) {
                        //         message = t('views.account.orangeMoneyCm');
                        //     } else if (res.response.data?.narration.includes('Insufficient')) {
                        //         message = t('views.account.insufficientFund');
                        //     } else if (res.response.data?.narration.includes('MTN CM')) {
                        //         message = t('views.account.mtnCm');
                        //     } if (res.response.data.narration?.includes('Orangemoney CI')) {
                        //         message = t('views.account.orangeMoneyCi');
                        //     } else {
                        //         message = res.response.data.narration;
                        //     }
                        //     setAlertType('warning');
                        //     setReleaseErrors(message);
                        //     setState({ ...state, request_transaction_id: true, transaction_ref: res.response.data.tx_ref, isValidated: false, isLoading: true });
                        //     let checkStatus = await RequestFanbusy(`accounts/credit/check-status/${res.response.data.tx_ref}`, 'PUT', "", users.access_token);
                        //     while (checkStatus.response.data.status === "PENDING_VALIDATION") {
                        //         await new Promise(resolve => setTimeout(resolve, 5000));
                        //         checkStatus = await RequestFanbusy(`accounts/credit/check-status/${res.response.data.tx_ref}`, 'PUT', "", users.access_token);
                        //     }

                        //     if (checkStatus.response.status_code === 201) {
                        //         let creditData = res.response.data
                        //         setState({ ...state, amount: '', mode: '', card_id: '', isLoaded: false });
                        //         submitFormTransactionByAction()
                        //         setAlertType('success');
                        //         setReleaseErrors(t('views.account.transactionSuccessful'));
                        //         dispatch({ type: "ACCOUNT", value: { account: creditData } });
                        //         dispatch({ type: "REFRESH", value: 1 });
                        //     } else if (checkStatus.response.status_code === 201 && checkStatus.response.description === 'Transaction failed') {
                        //         setAlertType('danger')
                        //         setReleaseErrors(t('views.account.transactionFailed'));
                        //         setState({ ...state, card_id: '', isLoaded: false });
                        //     } else {
                        //         let errorMessage = t('views.account.processingYourTransaction');
                        //         setState({ ...state, mode: '', card_id: '', isLoaded: false });
                        //         setAlertType('warning')
                        //         setReleaseErrors(errorMessage)
                        //     }

                        // } 
                        // else if (state.mode === 'CARD') {
                        //     if (res.response.data.redirect_url == null) {
                        //         setAlertType('warning');
                        //         setReleaseErrors(t('navigation.tryAgain'));
                        //         setShowReleaseError(true)
                        //     }
                        //     else {
                        //         window.location.replace(res.response.data.redirect_url);
                        //     }
                        //     setState({ ...state, tx_ref: res.response.data.tx_ref, isValidated: false, isLoading: false });
                        // }
                    }
                    else if (res.status === 400 && res.response.response_type === "Insuffisant fund") {
                        setShowReleaseError(true)
                        setAlertType('warning')
                        setReleaseErrors(res.response.description)
                        setState({ ...state, mode: '', isLoading: false });
                    }
                    else if (res.status === 400 && res.response.response_type === "Bad Request" && res.response.description === "Payment have an error") {
                        setShowReleaseError(true)
                        setAlertType('warning')
                        setReleaseErrors(res.response.data)
                        setState({ ...state, mode: '', isLoading: false });
                    }
                    else if (res.status === 400 && res.response.response_type === "Bad Request") {
                        setShowReleaseError(true)
                        setAlertType('warning')
                        setReleaseErrors(res.response.description)
                        setState({ ...state, mode: '', isLoading: false });
                    }
                    else if (res.status === 406 && res.response.response_type === "Not acceptable") {
                        setShowReleaseError(true)
                        setAlertType('danger')
                        setReleaseErrors(res.response.description)
                        setState({ ...state, mode: '', isLoading: false });
                    }

                    else if (res.status === 406 && res.response.description === "Your last transaction is being processed") {
                        setAlertType('warning')
                        setReleaseErrors(t('navigation.yourLastTransactionIsBeingProcessed'))
                        setShowReleaseError(true)
                        setState({ ...state, mode: '', isLoading: false });
                    }
                    else {
                        setAlertType('warning')
                        setReleaseErrors(t('navigation.tryAgain'))
                        setShowReleaseError(true)
                        setState({ ...state, mode: '', isLoading: false });
                    }
                } else {
                    setState((prev) => ({ ...prev, isLoading: true }));
                    const formValidated = {
                        tx_ref: state.tx_ref,
                        payment_mode: state.mode,
                        otp: state.otp,
                        card_id: cardId
                    };
                    let res = await RequestFanbusy(`accounts/payment/confirm/${users.account._id}`, 'POST', formValidated, users.access_token);

                    if (res.status === 200) {
                        setCredited(true)
                        submitFormTransactionByAction()
                        setState({ ...state, credited: true, isValidated: true, releaseErrors: '', isLoading: false });
                    }
                    else if (res.status === 422 && res.response.response_type === "Unprocessable Entity") {
                        setShowReleaseError(true)
                        setAlertType('warning');
                        setReleaseErrors(t('navigation.tryAgain'))
                        setState({ ...state, otp: '', mode: '', isValidated: false, isLoading: false });
                    }
                }
            }
        }
    }

    const isDisabled = (
        (state.mode === '') ||
        (action !== 'leaveTip' && state.amount < amount) ||
        state.amount === '' ||
        state.amount <= 0 ||
        // (state.mode === 'MOBILE_MONEY_XAF' && (users.users.phone_number === '') && state.phoneNumber === '') ||
        (action === 'leaveTip' && (state.amount > parseFloat(amountt.maxAmount.replace(/\s/g, '')) || state.amount < parseFloat(amountt.minAmount.replace(/\s/g, ''))))
    );

    const submitFormTransaction = (cardId) => {
        if (action === 'leaveTip') {
            if (checkBalanceCreator()) {
                handleSenTipCreator()
            } else {
                handleCreditAccount(cardId)
            }
        }
        else {
            handleCreditAccount(cardId)
        }
    }

    const handleRefrechModal = () => {
        setReleaseErrors('')
        setCredited(false)
        // setShowPhone(false)
        setShowNewCard(false)
        setState({ ...state, tx_ref: '', amount: action === 'leaveTip' ? 0 : state.amount, cardNumber: '', otp: '', phoneNumber: '', phoneCode: '', isValidated: true, mode: '', cardId: '', isLoading: false });
        setShowReleaseError(true);
        setRefreshKey(prevKey => prevKey + 1);

    }


    React.useEffect(() => {
        let maxAmountPost = convertCurrency(params.currency, params.currencies, 200, 'GET')
        let minAmountpost = convertCurrency(params.currency, params.currencies, 1, 'GET')
        setAmountt({ ...amountt, maxAmount: maxAmountPost, minAmount: minAmountpost })
        if (users.users._id !== data.id && data.id !== undefined) {
            let online = getStatutByUser(data.id);
            online.then((value) => {
                setIsOnLine(value)
            });
        }

        let paymentMode = getPaymentModeByCountry(users.access_token, params.country, params.currency);
        paymentMode.then((value) => {
            setState((prev) => ({ ...prev, paymentMode: value }));
        });

        let cardPayment = getPaymentCard(users.access_token);
        cardPayment.then((value) => {
            setState((prev) => ({ ...prev, cardPayment: value }));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.refresh, users.access_token, params.country, params.currencies, params.currency, data.id]);

    React.useEffect(() => {
        setState(prevState => ({ ...prevState, amount: amount }));
    }, [amount]);


    // const handleChangePhoneNumber = (value) => {
    //     setState(prevState => ({ ...prevState, phoneNumber: value }));
    // };

    // const handleCountryChange = (countryCode) => {
    //     setState(prevState => ({ ...prevState, phoneCode: countryCode }));
    // };


    function translatePaymentMode(label) {
        if (label === 'Mobile Money') {
            return t('views.account.mobileMoney')
        } else if (label === "Card") {
            return t('views.account.cardPayment')
        } else {
            return label
        }
    }

    return (
        <>
            <div key={refreshKey} className={`modal fade ${isModalOpen ? 'show' : ''}`} id={`sentTipModal${data.postId}`} data-bs-backdrop='static' data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className={`modal-dialog modal-dialog-centered d-flex align-items-center justify-content-center mw-web ${isModalOpen ? 'show' : ''}`}>
                    <div className="modal-content rounded-4 p-3 shadow-sm bg-white" style={{ width: '100%' }}>
                        <h5 className="mb-3">{nameForm}</h5>
                        <div className="modal-header d-flex align-items-center justify-content-start border-0 p-0 ">
                            <div className="modal-title text-muted d-flex align-items-center" id="staticBackdropLabel">
                                <div className="position-relative">
                                    <Avatar user={data} width={40} height={40} />
                                    {isOnLine &&
                                        <span className="rounded-circle position-absolute bottom-0 mb-1" style={{ width: 10, height: 10, background: '#35D063', right: '2px', border: '2px solid white' }}></span>
                                    }
                                </div>
                                <div className="text-decoration-none d-flex flex-column text-captalize ms-2">
                                    <h6 className="fw-bold mb-0 text-body mt-0 text-capitalize d-flex align-items-center">
                                        <span className="mx-0 p-0" >{shortenName(data.display_name, 15)} </span>
                                        {data.account_type === 'CREATOR' &&
                                            <span className="ms-1 p-0 md-16 fw-bold mb-1">{Images.verif} </span>
                                        }

                                    </h6>
                                    <small className="text-muted">@{shortenName(data.pseudo, 10)}</small>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body p-0 mb-3" >
                            <div className=" mt-3 possition-relative">
                                <input className={`form-control rounded-5 ${(action !== 'leaveTip') ? 'disabled bg-transparent' : ''}`} id="inputRising" style={{ height: '50px' }} name="tipAmount" value={state?.amount === 0 ? '' : state?.amount} onChange={textInputChange} disabled={action !== 'leaveTip'} />
                                <label htmlFor="inputRising" className="position-absolute start-0 ms-2 bg-white px-1 py-0" style={{ top: 6 }}>{t('navigation.amount')}{params.currency}</label>
                            </div>
                            {(action === 'leaveTip' && !checkBalanceCreator()) &&
                                <p className="my-1" style={{ marginLeft: '12px' }}>Minimum {amountt.minAmount} {params.currency} , Maximum {amountt.maxAmount} {params.currency}</p>
                            }
                            {((action === 'leaveTip' && !checkBalanceCreator()) || action !== 'leaveTip') &&
                                <>
                                    <div className="d-flex gap-3 mt-3 position-relative">
                                        <select id="floatingSelect" className={`${darkMode && 'bg-dark text-white'} form-select form-select-sm rounded-5 border`} aria-label=".form-select-sm example" style={{ height: '50px' }} name="modePaymentTip" onChange={textInputChange}>
                                            <option selected disabled>{t('navigation.chooseYourPaymentMode')}</option>
                                            {state.paymentMode?.map((mode, index) => {
                                                return (
                                                    <option key={index} value={mode.code}>
                                                        {translatePaymentMode(mode.label)}
                                                    </option>
                                                )
                                            })}
                                            {action === 'subscribeCreator' && <option value="pay4me">Pay4me</option>}

                                        </select>
                                        <label htmlFor="floatingSelect" className="position-absolute start-0 ms-2 bg-white px-1" style={{ top: -8 }}>{t('navigation.paymentMethod')}</label>
                                    </div>
                                    {/* {
                                        (state.mode === 'CARD') &&
                                            <>
                                                {
                                                    state.cardPayment.length > 0 && (
                                                        <div className=" d-flex align-items-center gap-2 mt-3 ">
                                                            <select className={`${darkMode && 'bg-dark text-white'} form-select form-select-sm rounded-5 py-1 `} aria-label=".form-select-sm example" style={{ height: '50px' }} name="cardId" onChange={textInputChange}>
                                                                <>
                                                                    <option selected disabled>{t('navigation.chooseCard')}</option>
                                                                    {state.cardPayment?.map((card, index) => {
                                                                            <option key={index} value={card._id}>{card.mask_number}</option>
                                                                        )
                                                                    })}
                                                        </div>
                                                )}
                                                {(state.cardPayment.length === 0 || showNewCard) &&
                                                    <div className="mt-3">
                                                        <AddCardPayment submitFormTransaction={submitFormTransaction} handleRefrechModal={handleRefrechModal} isValidated={state.isValidated} isLoaded={state.isLoading} transactionMoRef={transactionMoRef} visible={false} />
                                                    </div>
                                                }
                                            </>
                                    } */}
                                    {state.mode === 'pay4me' &&
                                        <div className="form-floating mt-2">
                                            <input name="pay4meCode" maxLength={15} value={state?.pay4meCode} onChange={textInputChange} type="email" className="form-control" id="floatingInput" placeholder="code" style={{ height: '50px' }} />
                                            <label for="floatingInput">{t('views.pay4me.insertTheCodePay4me')}</label>
                                        </div>

                                    }
                                    {/* {
                                        (state.mode === 'MOBILE_MONEY_XAF' && (users.users.phone_number === '' || showPhone)) &&
                                        <div className="align-items-center gap-2 mt-3" style={{ height: '50px' }}>
                                            <PhoneInput
                                                className="form-control rounded-5 customInputChild"
                                                style={{ width: '100%' }}
                                                value={state.phoneNumber}
                                                onChange={handleChangePhoneNumber}
                                                onCountryChange={handleCountryChange}
                                                placeholder={t('views.send_the_payment_methods.enterNumber')}
                                                defaultCountry="CM"
                                            />

                                        </div>
                                    }
                                    {
                                        (state.mode === 'MOBILE_MONEY_XAF' && users.users.phone_number !== '') &&
                                        <div className="d-flex align-items-center justify-content-between mt-2">
                                            <p className="mb-0" style={{ marginLeft: '12px' }}>{t('navigation.numberToCredit')}<span className="fb-color mb-0">{users.users.phone_number}</span></p>
                                            <button onClick={() => setShowPhone(!showPhone)} className="material-icons  p-0 md-20 fb-color bg-transparent border-0">edit_note</button>
                                        </div>
                                    } */}
                                </>
                            }
                            {state.request_transaction_id &&
                                <>
                                    <p className="mt-2 mb-1">{t('views.account.transactionIdLabel')}</p>
                                    <div className="form-floating mb-1 d-flex align-items-center">
                                        <input type="Rising" className={`${darkMode && 'bg-dark text-white'} form-control rounded-5`} id="transaction_id" placeholder="Transaction ID" name="transaction_id" value={state.transaction_id} onChange={textInputChange} style={{ height: '5px' }} />
                                        <label htmlFor="transaction_id" className="pt-2 mt-0">{t('views.account.transactionIdPlaceholder')}</label>
                                    </div>
                                </>
                            }
                        </div>
                        {((!showNewCard) || state.mode !== 'CARD') &&
                            <div>
                                <div className="d-flex align-items-center justify-content-end gap-3">
                                    <button onClick={handleRefrechModal} className="btn btn-block text-secondary p-0 rounded-pill fw-bold px-2" style={{ backgroundColor: "#F0F0F0" }} data-bs-dismiss="modal" ref={transactionMoRef}>{t('words.cancel')}</button>
                                    <button className={`btn btn-block p-0 rounded-pill fw-bold px-3 ${isDisabled ? 'disabled btn-secondary' : 'gradient-button text-white'}`} onClick={!isDisabled ? () => submitFormTransaction(state.cardId) : () => { }}>
                                        {state.isLoading ?
                                            <Loader /> :
                                            <>
                                                {state.isValidated ?
                                                    <span>{t('navigation.pay')}</span> :
                                                    <span>{t('views.account.confirm')}</span>
                                                }
                                            </>
                                        }
                                    </button>
                                </div>
                                {(releaseErrors?.length > 0 && showReleaseError) &&
                                    <div className={` mt-2 alert alert-${alertTpe} alert-dismissible`} role="alert">
                                        <div>{releaseErrors}</div>
                                        <button type="button" className="btn-close" onClick={() => setShowReleaseError(false)} aria-label="Close"></button>
                                    </div>
                                }
                                {state.request_transaction_id && <small className="fw-bold text-danger">{t('views.account.waiting_time')}</small>}
                            </div>
                        }
                    </div>
                </div>
            </div >
        </>
    )
}

export default TransactionsModal;