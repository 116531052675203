import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { AppContainer } from '../../Containers';
import NewMessage from './NewMessage';
import Room from './Room';
import TchatMessage from './TchatMessage';
import CreateRoom from './CreateRoom';
import SelectUser from './SelectUser';
import { useWebSocket } from '../../Services';

const TchatLayout = () => {
    const dataSocket = useWebSocket();
    const { pathname } = useLocation();
    const [isMobile, setIsMobile] = useState(false);
    const [showCreateRoom, setShowCreateRoom] = useState(false);
    const [currentView, setCurrentView] = useState('room');
    const [selectedRoom, setSelectedRoom] = useState('');
    const [selectedReceiver, setSelectedReceiver] = useState('');
    const [status, setStatus] = useState('');

    useEffect(() => {
        checkIsMobile();
        window.addEventListener("resize", checkIsMobile);
        return () => window.removeEventListener("resize", checkIsMobile);
    }, []);

    function checkIsMobile() {
        setIsMobile(window.innerWidth <= 768);
    }

    const handleSelectRoom = async (room, receiver, status) => {
        setSelectedRoom(room);
        setSelectedReceiver(receiver);
        setStatus(status);
    };
    const handleBackToRoom = () => {
    };

    useEffect(() => {
        let view = pathname.split(/\/[0-9a-f-]+\//);
        let views = view[0]

        if (views === '/messages/creat') {
            setShowCreateRoom(true);
            setCurrentView('room');
        } else if (views.startsWith('/messages/my/chat')) {
            setShowCreateRoom(false);
            setCurrentView('tchat');
        } else {
            setShowCreateRoom(false);
            setCurrentView('room');
        }

    }, [pathname]);

    return (
        <AppContainer>
            <main className={`col-12 col-xl-9 order-xl-2 col-lg-12 order-lg-1 col-md-6 col-sm-6 border border-top-0 border-bottom-0 bg-white vh-100`} >
                <div className='row'>
                    <div className={`col col-xl-4 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12 bg-transparent top-0 border border-top-0 border-bottom-0 vh-100 `} >
                        {showCreateRoom ?
                            <CreateRoom onSelectReceiver={handleSelectRoom} receiver={selectedReceiver} room={selectedRoom} />
                            :
                            (currentView === 'tchat' && isMobile ?
                                <TchatMessage onBack={handleBackToRoom} status={status} dataSocket={dataSocket} />
                                :
                                <Room onSelectReceiver={handleSelectRoom} dataSocket={dataSocket} />
                            )}
                    </div>
                    <div className={`col col-xl-8 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 messages-container position-relative border border-top-0 bg-white m-none`}>
                        {showCreateRoom ?
                            <SelectUser /> :
                            (currentView === 'tchat' ?
                                <TchatMessage onBack={handleBackToRoom} status={status} dataSocket={dataSocket} />
                                :
                                <NewMessage />
                            )}
                    </div>
                </div>
            </main>
        </AppContainer>
    );
};

export default TchatLayout;