import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatTimeToAmPm, renderFormatteContent } from "../../Utils";
import ModalImageView from "./ModalImageView";
import DropDownMenu from "./dropdownChat";
import ReplyPreview from "./replyPreview";

const MessageSendBySender = ({ msg, onReply }) => {
    const users = useSelector(state => state.users);
    const { t } = useTranslation();
    const dataReply = {
        content: msg.content,
        pseudo: users.users.pseudo,
        replyId: msg._id
    }
    const menuOptions = [
        { label: t('views.messages.replyTo'), onClick: () => onReply(dataReply) },
    ];

    return (
        <div key={msg._id} onContextMenu={(e) => e.preventDefault()}>
            <div className="d-flex justify-content-end mb-2 chatparentSend">
                <div className="me-2 small">
                    <div className="d-flex position-relative">
                        <DropDownMenu
                            className="mb-5 me-4"
                            classParent="start-0 translate-middle"
                            buttonClassName="dropstart dropchatSend"
                            menuOptions={menuOptions}

                        />
                        <div className="px-3 py-1 mb-0 chat-text border-raduis" style={{ backgroundColor: '#CDEAFC' }}>
                            {msg.file && msg.file.map((file, index) => (
                                <div key={index} className={`${index === file.length - 1 ? '' : 'mb-2'}`}>
                                    {file.link && (
                                        <>
                                            <ModalImageView msgId={file?._id} fileLink={file.link} pseudo={users.users.pseudo} />
                                            {file.mime_type === 'image' ? (
                                                <div className="position-relative" data-bs-toggle="modal" data-bs-target={`#p_imageview${file._id}`}>
                                                    <img className='img-fluid img' src={file.link} alt="file-img" style={{ maxHeight: 400, maxWidth: 280 }} onContextMenu={(e) => e.preventDefault()} />
                                                </div>
                                            ) : (
                                                <div className="position-relative">
                                                    <video src={file.link} preload="auto" controls controlsList="nodownload" className="img-fluid" style={{ maxHeight: 400, maxWidth: 280 }} onContextMenu={(e) => e.preventDefault()}></video>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            ))}
                            {msg.reply_content &&
                                <ReplyPreview replyContent={msg.reply_content} />
                            }
                            <>
                                {msg.content.length > 0 &&
                                    <span className={`text-muted fs-6 fw-200 d-inline-block mt-0 ${msg.content.length > 20 ? 'costom-control ' : 'normal'}`} >
                                        {renderFormatteContent(msg.content)}
                                    </span>
                                }
                            </>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <span className="small text-muted">{formatTimeToAmPm(msg.created_at, t) !== "Invalid Date" && formatTimeToAmPm(msg.created_at, t)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageSendBySender;
