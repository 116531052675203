import { React, useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { ChatHeader, EndOfPage, Loader, NoContent, UserChatItem } from '../../Components';
import { RequestFanbusy, TchatRequest } from '../../Services';
import { getRoomAndAllStatusReceiverByUser } from '../../Utils';
import { getStatutByUser, useInfiniteScroll } from '../../Utils';
// import { useSearch } from '../../Utils';



const CreateRoom = ({ onSelectReceiver, statusUsers, room }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const users = useSelector(state => state.users);
    const [isCreatingRoom, setIsCreatingRoom] = useState(false);
    const [isOnLine, setIsOnLine] = useState({});
    const [shosearchInput, setShosearchInput] = useState(false);
    const contactRef = useRef(null);
    // const { searchQuery, setSearchQuery, searchResults, searchLoad } = useSearch("/process/search/my/process");
    const [state, setState] = useState({
        isLoading: true,
        lazy: false,
        statusUsers,
        errorType: '',
        usersAll: []
    })
    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 25,
        last_page: 0,
        current_page: 0,
    })
    const nextPage = pagination.current_page + 1;

    const loadSubscription = useCallback(async () => {
        setState((prev) => ({ ...prev, lazy: true }));
        try {
            let res = await RequestFanbusy(`souscriptions/my/contact?limit=${pagination.per_page}&page=${nextPage}`, 'GET', '', users.access_token);
            const paginations = res.response.paginage;

            if (res.status === 200) {
                let subscriptions = res.response.data

                let newDataUsers = [];

                if (users.users?.account_type !== "CREATOR") {

                    newDataUsers = subscriptions?.filter((sub) => sub.fan._id === users.users._id)?.map((sub) => sub.creator);

                } else {

                    const fans = subscriptions.filter((sub) => sub.creator?._id === users.users?._id).map((sub) => sub.fan);
                    const creators = subscriptions.filter((sub) => sub.fan?._id === users.users?._id).map((sub) => sub.creator);

                    newDataUsers = [...fans, ...creators];

                    newDataUsers = Array.from(
                        new Map(newDataUsers.map((user) => [user._id, user])).values()
                    );
                }
                setPagination(prevState => ({ ...prevState, total: paginations.total, last_page: paginations.last_page, current_page: paginations.current_page }));

                setState((prev) => ({ ...prev, usersAll: [...prev.usersAll, ...newDataUsers], isLoading: false, lazy: false }));

                const onlineStatus = {};
                for (const creator of newDataUsers) {
                    const online = await getStatutByUser(creator._id);
                    onlineStatus[creator._id] = online;
                }
                setIsOnLine((prevStatus) => ({ ...prevStatus, ...onlineStatus }));
            }

        } catch (error) {
            setState((prev) => ({ ...prev, isLoading: false, lazy: false }));
        }
    }, [pagination.per_page, pagination.current_page]);

    useEffect(() => {
        loadSubscription()
        let stat = getRoomAndAllStatusReceiverByUser(users.users._id);
        stat.then((value) => {
            setState(prevState => ({ ...prevState, statusUsers: value }));
        });

    }, [users.access_token, users.users._id]);

    useEffect(() => {
        let isFetching = false;
        const container = contactRef.current
        const handleScroll = () => {
            if (container) {
                const { scrollTop, clientHeight, scrollHeight } = container;
                const scrollPosition = scrollTop + clientHeight;
                const scrollThreshold = scrollHeight - 500;

                if (!isFetching && scrollPosition >= scrollThreshold && pagination.current_page !== pagination.last_page && state.usersAll.length >= 25) {
                    isFetching = true;
                    loadSubscription()
                }
            }

        };

        container?.addEventListener('scroll', handleScroll);
        return () => container?.removeEventListener('scroll', handleScroll);
    }, [loadSubscription, pagination.current_page, pagination.last_page]);


    const createChatWithCreator = async (creator_id) => {
        const creatorResponse = await RequestFanbusy(`creators/${creator_id}`, 'GET', '', users.access_token);
        const newCreatorObject = creatorResponse.response.data;

        try {
            if (isCreatingRoom) {
                return;
            }
            setIsCreatingRoom(true);
            let data = {
                sender: {
                    id: users.users._id,
                },
                receiver: {
                    id: newCreatorObject._id,
                }
            }
            const response = await TchatRequest('room', 'POST', data, '');

            if (response.response.status_code === 201) {
                const newRoom = response.response.data._id;
                const res = await TchatRequest(`room/Room/souscriptions/${users.users._id}/${creator_id}`, 'PUT', '', '')
                if (res.response.status_code === 200) {
                    TchatRequest(`room/get/write/by/${room}/${users.users._id}/${newCreatorObject.tchat_fee}`, 'GET', '', '');
                    navigate(`my/chat/${newRoom}/${newCreatorObject._id}`)
                    onSelectReceiver(newRoom, newCreatorObject);
                }
            } else {
                const roomexist = response.response.data[0]._id;
                navigate(`my/chat/${roomexist}/${newCreatorObject._id}`)
                onSelectReceiver(roomexist, newCreatorObject);
            }

        } catch (error) {
            setState(prevState => ({ ...prevState, errorType: error.message }));
        }
    }


    return (
        <div className="main-content ">
            <ChatHeader t={t} />
            <div>
                {(state.isLoading) ?
                    <div style={{ marginTop: '50px' }}>
                        <Loader styleColor={true} />
                    </div>
                    :
                    <div ref={contactRef} className={`lg-0 size-contener-room`} style={{ overflowY: 'scroll' }} id="container-msg" >
                        {(state.usersAll.length <= 0) ?
                            <div className="sticky-sidebar2 mb-3 me-1">
                                <div class="py-5 text-center">
                                    <div class="text-center pb-5 mx-5">
                                        <NoContent />
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                {state.usersAll.map(user => {
                                    const onlineStatus = isOnLine[user._id];
                                    return (
                                        <UserChatItem
                                            user={user}
                                            onlineStatus={onlineStatus}
                                            createChatWithCreator={createChatWithCreator}
                                        />
                                    )
                                })}
                                {(state.lazy) && (
                                    <div className='mt-3'>
                                        <Loader styleColor={true} />
                                    </div>

                                )}
                                {(pagination.current_page === pagination.last_page && state.usersAll.length > 25 && !state.isLoading) &&
                                    <EndOfPage />
                                }
                            </>

                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default CreateRoom;